import PropTypes from 'prop-types';

import Button_Link_Container from 'components/Button/_container';

const Button = ({ children, className, ...props }) => (
	<Button_Link_Container el='button' className={className} {...props}>
		{children}
	</Button_Link_Container>
);

Button.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.object,
};

export default Button;
