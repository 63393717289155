import { useContext } from 'react';
import { graphql } from 'gatsby';
import { block } from 'bem-cn';

import Layout from 'layouts/';
import Heading from 'components/Heading';
import Container from 'components/Container';
import Paragraph from 'components/Paragraph';
import Title from 'components/Title';
import Image from 'components/Image';
import Divider from 'components/Divider';
import MenuItemButton from 'components/Button/_menuItem';
import { DeviceContext } from 'layouts/context';

import './style.scss';

const cn = block('video-page');

const Date = ({ date }) => (
	<Title color='secondary' level={8} isBlock>
		{date}
	</Title>
);

const VideoInformation = ({
	title,
	description,
	date,
	className,
	isTablet,
}) => (
	<div className={cn('video-information').mix(className)}>
		<Heading
			weight='bold'
			level={3}
			className={cn('video-information', { title: true })}
		>
			{title}
		</Heading>
		<Paragraph className={cn('video-information', { desc: true })}>
			{description}
		</Paragraph>
		{!isTablet && <Date date={date} />}
	</div>
);

const VideoReview = ({ videos, isTablet }) =>
	videos.map((video) => (
		<li key={video.title} className={cn('item', { wrap: true })}>
			<VideoInformation
				{...video}
				isTablet={isTablet}
				className={cn('item', { block: true })}
			/>
			<Image src='video' alt='video' className={cn('item', { block: true })} />
		</li>
	));

const VideoTitles = ({ titles }) => (
	<ul>
		{titles.map((title) => (
			<li key={title}>
				<Title>{title}</Title>
			</li>
		))}
	</ul>
);

const DownloadPage = ({ data }) => {
	const { isTablet } = useContext(DeviceContext);
	const { seo, title, description, videos } = data.markdownRemark.frontmatter;

	const firstVideo = videos[0];
	const videoTitles = videos.map((video) => video.title);

	return (
		<Layout seo={seo}>
			<Container className={cn('wrap')}>
				<Heading color='general' weight='bold' className={cn('title')}>
					{title}
				</Heading>
				<div className={cn('content')}>
					<Paragraph className={cn('description')}>{description}</Paragraph>
					<div className={cn('main-video', { wrap: true })}>
						<VideoInformation
							{...firstVideo}
							className={cn('main-video', { review: true })}
							isTablet={isTablet}
						/>
						<Image src='video' alt='video' />
						{isTablet && <Date date={firstVideo.date} />}
					</div>
				</div>
				<Divider />
				<div className={cn('review', { wrap: true })}>
					<MenuItemButton title='Bonds' className={cn('dropdown')}>
						<VideoTitles titles={videoTitles} />
					</MenuItemButton>
					<ul className={cn('review', { list: true })}>
						<VideoReview videos={videos} isTablet={isTablet} />
					</ul>
				</div>
			</Container>
		</Layout>
	);
};

export default DownloadPage;

export const query = graphql`
	query getVideoPageData {
		markdownRemark(frontmatter: { slug: { eq: "/video-tutorials" } }) {
			frontmatter {
				title
				seo {
					description
					image
					link
					title
				}
				description
				videos {
					title
					description
					date
					video
				}
			}
		}
	}
`;
