import { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { block } from "bem-cn";

import Button from "../";
import Title from "components/Title";

import "./style.scss";

const cn = block("menu-button");

const MenuItemButton = ({
  title,
  children,
  className,
  classNameTitle,
  isHoverButton,
  onClick,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handler = useCallback(() => {
    setIsOpen((state) => !state);
    onClick();
  }, [isHoverButton]);

  return (
    <>
      <Button
        className={cn("wrap").mix(className)}
        onClick={!isHoverButton ? handler : null}
        {...props}
      >
        <Title weight="medium" className={cn("title").mix(classNameTitle)}>
          {title}
        </Title>
        <span className={cn("arrow", { open: isOpen })} />
      </Button>
      {isOpen && children}
    </>
  );
};

MenuItemButton.defaultProps = {
  isHoverButton: false,
  onClick: () => {},
};

MenuItemButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.object,
  classNameTitle: PropTypes.object,
  isHoverButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export default MenuItemButton;
